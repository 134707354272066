<!-- 头部导航 -->
<template>
    <div >
        <div class="mheaderss" style="height:70px">
            <div style="display:flex;justify-content: space-between;">
                <img class="logos" src="../../static/home/logo.png">
                <div style="display:flex;line-height:70px;margin-right:50px;color:#C02722">
                    <div>
                        <el-popover
                            placement="right"
                            width="140"
                            trigger="click">
                                <div style="text-align:center" >
                                    <img :src="$imgurl+configuration.wx_img" alt="" style="width:100px;height:100px;margin:10px 0 ">
                                    <p style="font-size:16px">{{configuration.tel}}</p>
                                </div>
                                <img src="../../static/home/wechat.png" slot="reference" alt="" style="width:100px;height:30px;margin-top:20px;margin-right:50px">
                            </el-popover>
                       
                    </div>
                   <div style="display:flex;">
                        <img src="../../static/home/tel.png" alt="" style="width:20px;height:20px;margin-top:25px;margin-right:10px">
                        <div>{{configuration.tel}}</div>
                   </div>
                   
                </div>
                <!-- <img class="logoss" src="../../static/home/logo1.png"> -->
            </div>
        </div>
        <div :class="['navigation', { 'navigation1': fage || showSearch }]">
            
            <header class="pc-header">
                <!-- <img class="logo1" src="../../static/home/logo.png"> -->
                <img class="logo2" src="../../static/home/logo1.png">
            
                <main>
                    <div @click="us" @mouseenter="headers(0)" @mouseleave="headers(null)">
                        <i>首页</i>
                        <span></span>
                    </div>
                
                
                    <div @click="skip('/engineering')" @mouseenter="headers(3)" @mouseleave="headers(null)">
                        <i>师资团体</i>
                        <span></span>
                        <!-- <aside :class="{ 'aside': type == 3 }">
                            <ul>
                                <li v-for="(value, index) in list[2]" :key="index">{{ value.name }}</li>
                            </ul>
                        </aside> -->
                    </div>
                    <div @click="skip('/product')" @mouseenter="headers(2)" @mouseleave="headers(null)">
                        <i>报名专业</i>
                        <span></span>
                        <aside :class="{ 'aside': type == 2 }" >
                            <ul style="flex-wrap:wrap">
                                <li @click.stop="skip('/product', value.id)" v-for="(value, index) in CateList" :key="index">{{
                                    value.title }}</li>
                            </ul>
                        </aside>
                    </div>
                    <div @click="skip('/journalism')" @mouseenter="headers(4)" @mouseleave="headers(null)">
                        <i>政策咨询</i>
                        <span></span>
                        <!-- <aside :class="{ 'aside': type == 4 }">
                            <ul>
                                <li v-for="(value, index) in list[3]" :key="index">{{ value.name }}</li>
                            </ul>
                        </aside> -->
                    </div>
                    <div @click="skip('/contact')" @mouseenter="headers(5)" @mouseleave="headers(null)">
                        <i>报名入口</i>
                        <span></span>
                        <!-- <aside :class="{ 'aside': type == 5 }">
                            <ul>
                                <li @click.stop="uslist(value.class, '', '/contact')" v-for="(value, index) in list[4]"
                                    :key="index">{{
                                        value.name }}</li>
                            </ul>
                        </aside> -->
                    </div>
                    <div @click="uslist('introduce', 1, '/home')" @mouseenter="headers(1)" @mouseleave="headers(null)">
                        <i>关于我们</i>
                        <span></span>
                        <!-- <aside :class="{ 'aside': type == 1 }">
                            <ul>
                                <li @click.stop="uslist(value.class, '', '/home')" v-for="(value, index) in list[0]"
                                    :key="index">
                                    {{
                                        value.name }}</li>
                            </ul>
                        </aside> -->
                    </div>
                      <p class="p" style="border:none !important">
                         <el-popover
                            placement="right"
                            width="140"
                            trigger="click">
                                <div style="text-align:center" >
                                    <img :src="$imgurl+configuration.wx_img" alt="" style="width:100px;height:100px;margin:10px 0 ">
                                    <p style="font-size:16px">{{configuration.tel}}</p>
                                </div>
                               <img src="../../static/home/wechat.png" slot="reference" alt="" style="width:80px;height:30px;margin-right:70px;margin-top:4px">
                            </el-popover>
                       
                    </p>
                    <p @click="open">
                        <i class="el-icon-search"></i>
                    </p>
                    <p class="p" @click="unfold">
                        <i class="el-icon-more"></i>
                    </p>
                  
                    <aside :class="{ 'aside': showAside }">
                        <div>
                            <p @click="skip('/home')"><i>首页</i><i
                                    :class="['el-icon-caret-bottom', { 'i': typeAside === 99999 }]"></i></p>
                        </div>
                         <div>
                            <p @click="skip('/engineering')"><i>师资团队</i><i :class="['el-icon-caret-bottom', { 'i': typeAside == 3 }]"></i>
                            </p>
                            
                        </div>
                        <div>
                            <p @click="subnav(2)"><i>报名专业</i><i :class="['el-icon-caret-bottom', { 'i': typeAside == 2 }]"></i>
                            </p>
                            <div :class="{ 'div': typeAside == 2 }">
                                <p @click="skip('/product', value.id)" v-for="(value, index) in CateList"  v-if="index<=6" :key="index">{{
                                    value.title }}</p>
                            </div>
                        </div>
                       
                        <div>
                            <p @click="skip('/journalism')"><i>政策咨询</i><i :class="['el-icon-caret-bottom', { 'i': typeAside == 4 }]"></i>
                            </p>
                            <!-- <div :class="{ 'div': typeAside == 4 }">
                                <p @click="skip('/journalism')" v-for="(value, index) in list[3]" :key="index">{{ value.name }}
                                </p>
                            </div> -->
                        </div>
                        <div>
                            <p @click="skip('/contact')"><i>报名入口</i><i :class="['el-icon-caret-bottom', { 'i': typeAside == 5 }]"></i>
                            </p>
                            <!-- <div :class="{ 'div': typeAside == 5 }">
                                <p @click.stop="uslist(value.class, 1, '/contact')" v-for="(value, index) in list[4]"
                                    :key="index">{{
                                        value.name }}</p>
                            </div> -->
                        </div>
                        <div>
                            <p @click="uslist('introduce', 1, '/home')"><i>关于我们</i><i :class="['el-icon-caret-bottom', { 'i': typeAside == 1 }]"></i>
                            </p>
                            <!-- <div :class="{ 'div': typeAside == 1 }">
                                <p @click.stop="uslist('introduce', 1, '/home')" v-for="(value, index) in list[0]" :key="index">
                                    {{
                                        value.name }}</p>
                            </div> -->
                        </div>
                    </aside>
                </main>
                <div :class="['search', { 'show-search': showSearch }]">
                    <p>
                        <i class="el-icon-search"></i>
                        <input placeholder="请输入关键词"  type="text" v-model="search">
                        <i @click="open" class="el-icon-close" v-if="!search"></i>
                        <i @click="startSearch" class="el-icon-search" v-if="search"></i>
                    </p>
                </div>
            </header>
        </div>
    </div> 
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import bus from '@/config/emit'

export default {
    name: 'navigation',
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            showAside: false,
            showSearch: false,
            search: '',
            type: null,
            typeAside: null,
            CateList: ''
        };
    },
    props: ['fage', 'list', 'path','configuration'],
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        headers(index) {
            this.type = index
        },
        subnav(index) {
            if (this.typeAside == index) {
                this.typeAside = 0
                return
            }
            this.typeAside = index
        },
        open() {
            this.showSearch = !this.showSearch
            if (!this.showSearch) {
                this.search = ''
            } else {
                this.showAside = false
            }
        },
        unfold() {
            this.showSearch = false
            this.showAside = !this.showAside
        },
        us() {
            this.$router.push({
                path: '/home'
            })
        },
        uslist(dom, place, path) {
            if (this.path !== path) {
                this.$router.push({
                    path: path
                })
                localStorage.setItem('path', dom)
                if (place) {
                    this.showAside = false
                }
                return
            }
            if (place) {
                this.showAside = false
            }
            let top = document.getElementsByClassName(dom)[0].offsetTop
            this.$customApi.scrollToTop(top)
        },
        skip(route, id) {
            if (route == '/product') {
                bus.$emit('switchover', id)
            }
            this.showAside = false
            this.$router.push({
                name: route,
                params: { id, }
            })
        },
        getCateList() {
            this.$httpRequest('/index/getCateList').then(res => {
                this.CateList = res.data
            })
        },
        startSearch(){
            if(this.search){
                this.$router.push({
                    path: '/journalism',
                    query: { search: this.search}
                })
            }
        }
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getCateList()
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
@media screen and (min-width: 1600px) {
    
   
    .navigation {
        height: 1.025rem !important;

        &>.pc-header {
            &>.show-search {
                height: 2.5rem !important;
            }

            &>.search {
                &>p {
                    height: .7125rem !important;

                    &>input {
                        font-size: .1875rem !important;
                        padding-left: .25rem !important;
                    }

                    &>.el-icon-search {
                        font-size: .2rem !important;
                        color: #1b5fad;
                    }

                    &>.el-icon-close {
                        font-size: .25rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .navigation {
        height: 82px;
    }
    .logo1{
        display: none !important;
    }
    .logo2{
        display: none !important;
    }
}

@media screen and (max-width: 1000px) {
     .mheaderss{
        display: none;
    }
    // .logo1{
    //     display: block !important;
    // }
    .logo2{
        display: block !important;
    }
    .navigation {
        height: 60px;
        background-color: #fff !important;
        padding: 0 20px;
        .navigation1;
        box-sizing: border-box;
       
        &>.pc-header {
            width: 100% !important;

            &>img {
                height: 23px !important;
            }

            &>main {
                &>div {
                    display: none !important;
                }

                &>.p {
                    display: flex !important;

                    &>i {
                        font-size: 30px;
                    }
                }

                &>p {
                    border: 1px solid #ccc !important;
                    background: #fff !important;
                    color: #666 !important;
                }
            }

            &>.show-search {
                height: 118px !important;
                background: #ffffff !important;
            }
        }
    }
}

@media screen and (max-width: 2000px) {
    .logo1{
        display: none !important;
    }
    .logo2{
        display: none !important;
    }
     .logos{
        display: black !important;
        height: 50px;
        margin-top: 10px;
        margin-left: 50px;
        
    }
    .mheaderss{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        background: white;
    }
    .navigation {
        &>.pc-header {
            max-width: 1366px !important;

            &>main {
                max-width: 850px !important;

                &>aside {
                    &>ul {
                        max-width: 1366px !important;

                        &>li {
                            margin-right: 70px !important;
                        }
                    }
                }

                &>div {
                    font-size: 16px !important;
                    margin-right: 65px !important;
                }
            }

            &>img {
                height: 35px !important;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .navigation {
        &>.pc-header {
            max-width: 1200px !important;

            &>main {
                max-width: 700px !important;

                &>p {
                    width: .8rem !important;
                    height: .8rem !important;
                    font-size: 16px !important;
                }

                &>aside {
                    &>ul {
                        max-width: 1200px !important;

                        &>li {
                            margin-right: 50px !important;
                        }
                    }
                }

                &>div {
                    font-size: 15px !important;
                    margin-right: 30px !important;
                }
            }

            &>img {
                height: 33px !important;
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    .navigation {
        height: 90px;
    }
}

@media screen and (max-width: 1000px) {
    .logo2{
        display: block !important;
    }
    .mheaderss{
        display: none;
    }
    .navigation {
        position: sticky !important;

        &>.pc-header {
            &>img {
                height: 23px !important;
            }

            &>main {
                &>aside {
                    display: block !important;
                }

                &>p {
                    width: 36px !important;
                    height: 36px !important;
                    box-sizing: border-box;
                }
            }
        }
    }
}

@keyframes move {
    0% {
        transform: translateY(150%);
        opacity: 0;
    }

    50% {
        transform: translateY(-50%);
        opacity: 0.5;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

.navigation {
    width: 100%;
    background: #00000030;
    transition: all .3s;
    position: fixed;
    top: 70px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &>.pc-header {
        width: 19.2rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all .3s;

        &>.search {
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            height: 0px;
            background: #ffffff00;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s;
            overflow: hidden;

            &>p {
                width: 80%;
                height: 57px;
                border-bottom: 1px solid #d9d9d9;
                display: flex;
                align-items: center;

                &>input {
                    flex: 1;
                    font-size: 15px;
                    height: 100%;
                    padding-left: 20px;
                    background: #ffffff00;
                }

                &>.el-icon-search {
                    font-size: 16px;
                    color: #1b5fad;
                }

                &>.el-icon-close:hover {
                    transform: rotateZ(180deg);
                }

                &>.el-icon-close {
                    font-size: 20px;
                    color: #999;
                    transition: all .3s;
                }
            }
        }

        &>.show-search {
            height: 200px;
            background: #ffffff;
        }

        &>main {
            width: 13.5rem;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &>.aside {
                min-height: 600px;

                &>div {
                    height: auto;
                    line-height: 50px;
                    opacity: 1;
                }
            }

            &>aside {
                width: 100%;
                height: 0px;
                position: absolute;
                left: 0;
                top: 100%;
                display: none;
                overflow: hidden;
                transition: all .3s;
                background-color: #fff;

                &>div {
                    width: 100%;
                    height: 0px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #e3e3e3;
                    line-height: 0px;
                    transition: all .5s;
                    opacity: 0;
                    padding: 0 20px;

                    &>.div {
                        line-height: 50px;
                        opacity: 1;
                    }

                    &>div {
                        line-height: 0px;
                        transition: all .3s;
                        overflow: hidden;
                        opacity: 0;
                    }

                    &>p {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;

                        &>.i {
                            transform: rotateZ(180deg);
                            transition: all .1s;
                        }

                        &>i {
                            font-size: 20px;
                        }
                    }
                }
            }

            &>.p {
                margin-left: .25rem;
                display: none;
                font-size: .2rem;
            }

            &>p {
                width: .5rem;
                height: .5rem;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1b5fad;
                transition: all .3s;
                font-size: .2rem;
            }

            &>div:hover {
                &>span {
                    width: 100%;
                }
            }

            &>div {
                height: 100%;
                font-size: .225rem;
                color: #fff;
                margin-right: 78px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                transition: all .3s;

                &>.aside {
                    height: 200%;
                    background: #ffffff98;
                    overflow: none;

                    // &>ul {
                    //     &>li {
                            
                    //     }

                    //     &>li:nth-child(1) {
                    //         animation: move .8s linear 0s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(2) {
                    //         animation: move .8s linear .2s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(3) {
                    //         animation: move .8s linear .4s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(4) {
                    //         animation: move .8s linear .6s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(5) {
                    //         animation: move .8s linear .8s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(6) {
                    //         animation: move .8s linear 1s 1;
                    //         animation-fill-mode: forwards;
                    //     }

                    //     &>li:nth-child(7) {
                    //         animation: move .8s linear 1.2s 1;
                    //         animation-fill-mode: forwards;
                    //     }
                    // }
                }

                &>aside {
                    width: 100%;
                    height: 0%;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    background: #ffffff00;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s;
                    overflow: hidden;
                    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);

                    &>ul {
                        width: 19.2rem;
                        padding-right: 40px;
                        height: 100%;
                        color: #555;
                        list-style-type: square;
                        list-style-color: #999;
                        display: flex;
                        // justify-content: space-between;
                        box-sizing: border-box;

                        &>li:hover {
                            color: #000;
                        }

                        &>li {
                            margin: auto;
                            margin-left: 0;
                            margin-right: .975rem;
                            padding-top: 10px;
                        }
                    }
                }

                &>i {
                    margin: auto;
                }

                &>span {
                    width: 0;
                    height: 2px;
                    background: #1b5fad;
                    transition: all .3s;
                }
            }
        }

        &>.logo1 {
            display: block;
        }

        &>.logo2 {
            display: none;
        }

        &>img {
            height: .725rem;
            margin-left: 10px;
            transition: all .3s;
        }
    }
}

.navigation1 {
    background: #fff !important;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);

    &>.pc-header {
        &>main {
            &>div {
                color: #333 !important;
            }

            &>p {
                background-color: #1b5fad;
                color: #fff;
            }
        }

        &>.logo1 {
            display: none;
        }

        &>.logo2 {
            display: block;
        }
    }
}

.navigation:hover {
    .navigation1;
}</style>