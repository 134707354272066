<template>
  <div id="app">
    <navigation :fage="fage" :list="list" :path="route" :configuration="configuration" ></navigation>
    <keep-alive>
      <router-view :list="list[1]" :configuration="configuration"></router-view>
    </keep-alive>
    <top :fage="fage"></top>
    <bottom :show="bottomshow" :list="list" :configuration="configuration" :path="route"></bottom>
  </div>
</template>

<script>
import navigation from '@/components/navigation/navigation.vue'
import top from '@/components/top/top.vue'
import bottom from '@/components/bottom/bottom.vue'

export default {
  name: 'App',
  components: { navigation, top, bottom },
  data() {
    //这里存放数据
    return {
      fage: false,
      lastScrollPos: 0,
      rou: '',
      configuration:{},
      list: [[
        {
          name: '公司介绍',
          class: 'introduce',
        },
        {
          name: '工程案例',
          class: 'case',
        },
        // {
        //   name: '合作伙伴',
        //   class: 'Cooperation',
        // },
      ],
      [
        {
          name: '夯土抹装',
          id: 1
        },
        {
          name: 'UHPC超高强夯土板',
          id: 2
        },
        {
          name: '新型夯土墙',
          id: 3
        },
        {
          name: '老土墙',
          id: 4
        },
        {
          name: '混凝土粉料',
          id: 5
        },
      ], [
        { name: '经典案例' },
      ], [
        { name: '公司新闻' },
      ], [
        {
          name: '联系方式',
          class: 'context'
        },
        {
          name: '在线留言',
          class: "main"
        },
      ],]
    };
  },
  computed: {
    route() {
      return this.$route.path
    },
    bottomshow() {
      if (this.$route.path == '/details') {
        return false
      } else {
        return true
      }
    }
  },
  watch: {

  },
  mounted() {
    this.scroll()
    window.onscroll = () => {
      this.scroll()
    }
  }, 
  created() {
    this.config()
  },
  methods: {
    scroll() {
      const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
      if (scrollPos > 500) {
        this.fage = true
      } else if (scrollPos < 500) {
        this.fage = false
      }
    },
    config() {
      this.$httpRequest('/index/config').then(res => {
        this.configuration=res.data
      })
    }
  }
}
</script>

<style></style>
